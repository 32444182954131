//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------
// cerulean   cosmo    cyborg  darkly  flatly
// journal    litera   lumen   lux     materia
// minty      morph    pulse   quartz  sandstone
// simplex    sketchy  slate   solar   spacelab
// superhero  united   vapor   yeti    zephyr
@import "~bootswatch/dist/zephyr/variables";
@import "~bootstrap/scss/bootstrap";
@import "~bootswatch/dist/zephyr/bootswatch";
@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {
    font-family: 'PT Sans';
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/slider";

@import '~boxicons/css/boxicons.css';
@import '~aos/dist/aos';

@import './style';

/**
* Template Name: MyResume - v4.7.0
* Template URL: https://bootstrapmade.com/free-html-bootstrap-template-my-resume/
* Author: BootstrapMade.com
* License: https://bootstrapmade.com/license/
*/

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

body {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

a {
  color: $primary;
  text-decoration: none;
}

a:hover {
  color: $link-hover-color;
  text-decoration: none;
}


/*--------------------------------------------------------------
# Back to top button
--------------------------------------------------------------*/
.back-to-top {
  position: fixed;
  visibility: hidden;
  opacity: 0;
  right: 15px;
  bottom: 15px;
  z-index: 996;
  border-radius: 50px;
  transition: all 0.4s;
}
.back-to-top i {
  font-size: 28px;
  line-height: 1.35;
}
.back-to-top.active {
  visibility: visible;
  opacity: 1;
}

/*--------------------------------------------------------------
# Preloader
--------------------------------------------------------------*/
#preloader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  overflow: hidden;
  background: $white;
}

#preloader:before {
  content: "";
  position: fixed;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
  border: 6px solid $primary;
  border-top-color: $white;
  border-bottom-color: $white;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  -webkit-animation: animate-preloader 1s linear infinite;
  animation: animate-preloader 1s linear infinite;
}

@-webkit-keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes animate-preloader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
/*--------------------------------------------------------------
# Disable aos animation delay on mobile devices
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  [data-aos-delay] {
    transition-delay: 0 !important;
  }
}
/*--------------------------------------------------------------
# Header
--------------------------------------------------------------*/
#header {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 9997;
  transition: all 0.5s;
  padding: 15px;
  overflow-y: auto;
}
@media (max-width: 992px) {
  #header {
    width: 300px;
    background: $white;
    border-right: 1px solid #e6e9ec;
    left: -300px;
  }
}

@media (min-width: 992px) {
  #main {
    margin-left: 100px;
  }
}
/*--------------------------------------------------------------
# Navigation Menu
--------------------------------------------------------------*/
/**
* Desktop Navigation 
*/
.nav-menu {
  padding: 0;
  display: block;
  border: none;
  box-shadow: none;
  background: none;
}
.nav-menu * {
  margin: 0;
  padding: 0;
  list-style: none;
}
.nav-menu > ul > li {
  position: relative;
  white-space: nowrap;
}
.nav-menu a, .nav-menu a:focus {
  display: flex;
  align-items: center;
  color: $navbar-light-color;
  padding: 10px 18px;
  margin-bottom: 8px;
  transition: 0.3s;
  font-size: 15px;
  border-radius: 50px;
  background-color: $navbar-dark-color;
  height: 56px;
  width: 100%;
  overflow: hidden;
  transition: 0.3s;
}
.nav-menu a i, .nav-menu a:focus i {
  font-size: 20px;
}
.nav-menu a span, .nav-menu a:focus span {
  padding: 0 5px 0 7px;
  color: $body-color;
}
@media (min-width: 992px) {
  .nav-menu a, .nav-menu a:focus {
    width: 56px;
  }
  .nav-menu a span, .nav-menu a:focus span {
    display: none;
    color: $white;
  }
}
.nav-menu a:hover, .nav-menu .active, .nav-menu .active:focus, .nav-menu li:hover > a {
  color: $navbar-dark-active-color;
  background-color: $primary !important;
}
.nav-menu a:hover span, .nav-menu .active span, .nav-menu .active:focus span, .nav-menu li:hover > a span {
  color: $navbar-dark-active-color;
}
.nav-menu a:hover, .nav-menu li:hover > a {
  width: 100%;
  color: $navbar-dark-active-color;
}
.nav-menu a:hover span, .nav-menu li:hover > a span {
  display: block;
}

/**
* Mobile Navigation 
*/
.mobile-nav-toggle {
  position: fixed;
  right: 10px;
  top: 10px;
  z-index: 9998;
  border: 0;
  background: none;
  font-size: 38px;
  transition: all 0.4s;
  outline: none !important;
  line-height: 1;
  cursor: pointer;
  border-radius: 50px;
  padding: 5px;
}
.mobile-nav-toggle i {
  color: $body-color;
}

.mobile-nav-active {
  overflow: hidden;
}
.mobile-nav-active #header {
  left: 0;
}
.mobile-nav-active .mobile-nav-toggle {
  color: $white;
  background-color: $primary;
}

/*--------------------------------------------------------------
# Hero Section
--------------------------------------------------------------*/
#hero {
  width: 100%;
  height: 100vh;
  background: url("../img/hero-bg.jpg") top right no-repeat;
  background-size: cover;
  position: relative;
}
@media (min-width: 992px) {
  #hero {
    padding-left: 160px;
  }
}
#hero:before {
  content: "";
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
}
#hero .hero-title {
  margin: 0;
  font-size: 64px;
  font-weight: 700;
  line-height: 56px;
  color: $gray-900;
}
#hero p {
  color: $gray-700;
  margin: 15px 0 0 0;
  font-size: 26px;
}
#hero p span {
  color: $info;
  letter-spacing: 1px;
}
#hero .social-links, #hero .action-button {
  margin-top: 30px;
}
#hero .social-links a {
  font-size: 24px;
  display: inline-block;
  color: $gray-700;
  line-height: 1;
  margin-right: 20px;
  transition: 0.3s;
}
#hero .social-links a:hover {
  color: $primary;
}
@media (max-width: 992px) {
  #hero {
    text-align: center;
  }
  #hero h1 {
    font-size: 32px;
    line-height: 36px;
  }
  #hero p {
    margin-top: 10px;
    font-size: 20px;
    line-height: 24px;
  }
}

/*--------------------------------------------------------------
# Sections General
--------------------------------------------------------------*/
img {
  max-width: 100%;
}

section {
  padding: 60px 0;
  overflow: hidden;
  min-height: 50vh;
}
section blockquote {
  text-align: center;
}
.section-title, h1 {
  text-align: center;
  padding-bottom: 30px;
}
.section-title h1, h1 {
  font-size: 32px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-bottom: 20px;
  position: relative;
}
.section-title h1::before, h1::before {
  content: "";
  position: absolute;
  display: block;
  width: 120px;
  height: 1px;
  background: #ddd;
  bottom: 1px;
  left: calc(50% - 60px);
}
.section-title h1::after, h1::after {
  content: "";
  position: absolute;
  display: block;
  width: 40px;
  height: 3px;
  background: $primary;
  bottom: 0;
  left: calc(50% - 20px);
}
.section-title p {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# About
--------------------------------------------------------------*/
.about .content h3 {
  font-weight: 700;
  font-size: 26px;
  color: $secondary;
}
.about .content ul {
  list-style: none;
  padding: 0;
}
.about .content ul li {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}
.about .content ul strong {
  margin-right: 10px;
}
.about .content ul i {
  font-size: 16px;
  margin-right: 5px;
  color: $primary;
  line-height: 0;
}
.about .content p:last-child {
  margin-bottom: 0;
}

/*--------------------------------------------------------------
# Facts
--------------------------------------------------------------*/
.facts .count-box {
  padding: 30px 30px 25px 30px;
  margin-top: 30px;
  width: 100%;
  position: relative;
  text-align: center;
  background: $white;
}
.facts .count-box i {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 24px;
  background: $primary;
  color: $white;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
}
.facts .count-box span {
  font-size: 36px;
  display: block;
  font-weight: 600;
  color: #011426;
}
.facts .count-box p {
  padding: 0;
  margin: 0;
  font-size: 14px;
}

/*--------------------------------------------------------------
# Skills
--------------------------------------------------------------*/
.skills .progress {
  height: 60px;
  display: block;
  background: none;
  border-radius: 0;
}
.skills .progress .skill {
  padding: 10px 0;
  margin: 0;
  text-transform: uppercase;
  display: block;
  font-weight: 600;
  color: $body-color;
}
.skills .progress .skill .val {
  float: right;
  font-style: normal;
}
.skills .progress-bar-wrap {
  background: #f2f3f5;
}
.skills .progress-bar {
  width: 1px;
  height: 10px;
  transition: 0.9s;
  background-color: $primary;
}

/*--------------------------------------------------------------
# Timeline
--------------------------------------------------------------*/
.timeline h2 {
  font-size: 26px;
  font-weight: 700;
  margin-top: 20px;
  margin-bottom: 20px;
  color: $body-color;
}
.timeline > ul > li {
  padding: 0 0 20px 20px;
  margin-top: -2px;
  border-left: 2px solid $primary;
  position: relative;
  list-style-type: none;
}
.timeline > ul > li p {
  margin-bottom: 0;
}
.timeline > ul > li strong:first-child {
  line-height: 18px;
  font-size: 18px;
  font-weight: 600;
  text-transform: uppercase;
  color: $primary;
  margin-bottom: 10px;
}
.timeline > ul > li em {
  font-size: 16px;
  background: #f7f8f9;
  padding: 5px 15px;
  display: inline-block;
  font-weight: 600;
  font-style: normal;
  margin-bottom: 10px;
}
// .publications li dd, .publications li dl {
//   margin-bottom: 0;
// }
// .publications .timeline-item ul {
//   padding-left: 20px;
// }
// .publications .timeline-item ul li {
//   padding-bottom: 10px;
// }
.timeline > ul > li:last-child {
  padding-bottom: 0;
}
.timeline > ul > li::before {
  content: "";
  position: absolute;
  width: 16px;
  height: 16px;
  border-radius: 50px;
  left: -9px;
  top: 0;
  background: $white;
  border: 2px solid $primary;
}

/*--------------------------------------------------------------
# Projects
--------------------------------------------------------------*/
.projects .projects-item {
  margin-bottom: 30px;
}
.projects #projects-filters {
  padding: 0;
  margin: 0 auto 25px auto;
  list-style: none;
  text-align: center;
  background: $white;
  border-radius: 50px;
  padding: 2px 15px;
}
.projects #projects-filters li {
  cursor: pointer;
  display: inline-block;
  padding: 10px 15px;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  text-transform: uppercase;
  color: #272829;
  margin-bottom: 5px;
  transition: all 0.3s ease-in-out;
}
.projects #projects-filters li:hover, .projects #projects-filters li.filter-active {
  color: $primary;
}
.projects #projects-filters li:last-child {
  margin-right: 0;
}
.projects .projects-wrap {
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  z-index: 1;
  //background: rgba(69, 80, 91, 0.8);
  border: 1px solid $light;
}
.projects .projects-wrap::before {
  content: "";
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  left: 30px;
  right: 30px;
  top: 30px;
  bottom: 30px;
  transition: all ease-in-out 0.3s;
  z-index: 2;
  opacity: 0;
}
.projects .projects-wrap .projects-info {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all ease-in-out 0.3s;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.projects .projects-wrap .projects-info::before {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  top: 35px;
  left: 35px;
  border-top: 3px solid #d7dce1;
  border-left: 3px solid #d7dce1;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.projects .projects-wrap .projects-info::after {
  display: block;
  content: "";
  width: 48px;
  height: 48px;
  position: absolute;
  bottom: 35px;
  right: 35px;
  border-bottom: 3px solid #d7dce1;
  border-right: 3px solid #d7dce1;
  transition: all 0.5s ease 0s;
  z-index: 9994;
}
.projects .projects-wrap .projects-info h4 {
  font-size: 20px;
  color: $body-color;
  font-weight: 600;
}
.projects .projects-wrap .projects-info p {
  color: $body-color;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  margin: 0;
}
.projects .projects-wrap .projects-links {
  text-align: center;
  z-index: 4;
}
.projects .projects-wrap .projects-links a {
  color: $body-color;
  margin: 0 2px;
  font-size: 28px;
  display: inline-block;
  transition: 0.3s;
}
.projects .projects-wrap .projects-links a:hover {
  color: #148af9;
}
.projects .projects-wrap:hover::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 1;
}
.projects .projects-wrap:hover .projects-info {
  opacity: 1;
}
.projects .projects-wrap:hover .projects-info::before {
  top: 15px;
  left: 15px;
}
.projects .projects-wrap:hover .projects-info::after {
  bottom: 15px;
  right: 15px;
}

/*--------------------------------------------------------------
# Projects Details
--------------------------------------------------------------*/
.projects-details {
  padding-top: 40px;
}
.projects-details .projects-details-slider img {
  width: 100%;
}
.projects-details .projects-details-slider .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.projects-details .projects-details-slider .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: $white;
  opacity: 1;
  border: 1px solid $primary;
}
.projects-details .projects-details-slider .swiper-pagination .swiper-pagination-bullet-active {
  background-color: $primary;
}
.projects-details .projects-info {
  padding: 30px;
  box-shadow: 0px 0 30px rgba(69, 80, 91, 0.08);
}
.projects-details .projects-info h3 {
  font-size: 22px;
  font-weight: 700;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid #eee;
}
.projects-details .projects-info ul {
  list-style: none;
  padding: 0;
  font-size: 15px;
}
.projects-details .projects-info ul li + li {
  margin-top: 10px;
}
.projects-details .projects-description {
  padding-top: 30px;
}
.projects-details .projects-description h2 {
  font-size: 26px;
  font-weight: 700;
  margin-bottom: 20px;
}
.projects-details .projects-description p {
  padding: 0;
}

/*--------------------------------------------------------------
# Services
--------------------------------------------------------------*/
.services .icon-box {
  text-align: center;
  padding: 70px 20px 80px 20px;
  transition: all ease-in-out 0.3s;
  background: $white;
  box-shadow: 0px 5px 90px 0px rgba(110, 123, 131, 0.05);
}
.services .icon-box .icon {
  margin: 0 auto;
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ease-in-out 0.3s;
  position: relative;
}
.services .icon-box .icon i {
  font-size: 36px;
  transition: 0.5s;
  position: relative;
}
.services .icon-box .icon svg {
  position: absolute;
  top: 0;
  left: 0;
}
.services .icon-box .icon svg path {
  transition: 0.5s;
  fill: #f5f5f5;
}
.services .icon-box h4 {
  font-weight: 600;
  margin: 10px 0 15px 0;
  font-size: 22px;
}
.services .icon-box h4 a {
  color: $body-color;
  transition: ease-in-out 0.3s;
}
.services .icon-box p {
  line-height: 24px;
  font-size: 14px;
  margin-bottom: 0;
}
.services .icon-box:hover {
  border-color: $white;
  box-shadow: 0px 0 35px 0 rgba(0, 0, 0, 0.08);
}
.services .iconbox-blue i {
  color: #47aeff;
}
.services .iconbox-blue:hover .icon i {
  color: $white;
}
.services .iconbox-blue:hover .icon path {
  fill: #47aeff;
}
.services .iconbox-orange i {
  color: #ffa76e;
}
.services .iconbox-orange:hover .icon i {
  color: $white;
}
.services .iconbox-orange:hover .icon path {
  fill: #ffa76e;
}
.services .iconbox-pink i {
  color: #e80368;
}
.services .iconbox-pink:hover .icon i {
  color: $white;
}
.services .iconbox-pink:hover .icon path {
  fill: #e80368;
}
.services .iconbox-yellow i {
  color: #ffbb2c;
}
.services .iconbox-yellow:hover .icon i {
  color: $white;
}
.services .iconbox-yellow:hover .icon path {
  fill: #ffbb2c;
}
.services .iconbox-red i {
  color: #ff5828;
}
.services .iconbox-red:hover .icon i {
  color: $white;
}
.services .iconbox-red:hover .icon path {
  fill: #ff5828;
}
.services .iconbox-teal i {
  color: #11dbcf;
}
.services .iconbox-teal:hover .icon i {
  color: $white;
}
.services .iconbox-teal:hover .icon path {
  fill: #11dbcf;
}

/*--------------------------------------------------------------
# Testimonials
--------------------------------------------------------------*/
.testimonials .section-header {
  margin-bottom: 40px;
}
.testimonials .testimonials-carousel, .testimonials .testimonials-slider {
  overflow: hidden;
}
.testimonials .testimonial-item {
  text-align: center;
}
.testimonials .testimonial-item .testimonial-img {
  width: 120px;
  border-radius: 50%;
  border: 4px solid $white;
  margin: 0 auto;
}
.testimonials .testimonial-item h3 {
  font-size: 20px;
  font-weight: bold;
  margin: 10px 0 5px 0;
  color: #111;
}
.testimonials .testimonial-item h4 {
  font-size: 14px;
  color: #999;
  margin: 0 0 15px 0;
}
.testimonials .testimonial-item .quote-icon-left, .testimonials .testimonial-item .quote-icon-right {
  color: #90c8fc;
  font-size: 26px;
}
.testimonials .testimonial-item .quote-icon-left {
  display: inline-block;
  left: -5px;
  position: relative;
}
.testimonials .testimonial-item .quote-icon-right {
  display: inline-block;
  right: -5px;
  position: relative;
  top: 10px;
}
.testimonials .testimonial-item p {
  font-style: italic;
  margin: 0 auto 15px auto;
}
.testimonials .swiper-pagination {
  margin-top: 20px;
  position: relative;
}
.testimonials .swiper-pagination .swiper-pagination-bullet {
  width: 12px;
  height: 12px;
  background-color: $white;
  opacity: 1;
  border: 1px solid $primary;
}
.testimonials .swiper-pagination .swiper-pagination-bullet-active {
  background-color: $primary;
}
@media (min-width: 992px) {
  .testimonials .testimonial-item p {
    width: 80%;
  }
}

/*--------------------------------------------------------------
# Contact
--------------------------------------------------------------*/
.contact .info {
  width: 100%;
}
.contact .info i {
  font-size: 20px;
  color: $primary;
  float: left;
  width: 44px;
  height: 44px;
  background: #eef7ff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  transition: all 0.3s ease-in-out;
}
.contact .info h4 {
  padding: 0 0 0 60px;
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 5px;
  color: $body-color;
}
.contact .info p {
  padding: 0 0 0 60px;
  margin-bottom: 0;
  font-size: 14px;
  color: #728394;
}
.contact .info .email, .contact .info .phone {
  margin-top: 40px;
}
.contact .info .email:hover i, .contact .info .address:hover i, .contact .info .phone:hover i {
  background: $primary;
  color: $white;
}
.contact .php-email-form {
  width: 100%;
}
.contact .php-email-form .form-group {
  padding-bottom: 8px;
}
.contact .php-email-form .error-message {
  display: none;
  color: $white;
  background: #ed3c0d;
  text-align: left;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .error-message br + br {
  margin-top: 25px;
}
.contact .php-email-form .sent-message {
  display: none;
  color: $white;
  background: #18d26e;
  text-align: center;
  padding: 15px;
  font-weight: 600;
}
.contact .php-email-form .loading {
  display: none;
  background: $white;
  text-align: center;
  padding: 15px;
}
.contact .php-email-form .loading:before {
  content: "";
  display: inline-block;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin: 0 10px -6px 0;
  border: 3px solid #18d26e;
  border-top-color: #eee;
  -webkit-animation: animate-loading 1s linear infinite;
  animation: animate-loading 1s linear infinite;
}
// .contact .php-email-form input, .contact .php-email-form textarea {
//   border-radius: 4px;
//   box-shadow: none;
//   font-size: 14px;
// }
// .contact .php-email-form input {
//   height: 44px;
// }
// .contact .php-email-form textarea {
//   padding: 10px 12px;
// }
// .contact .php-email-form button[type=submit] {
//   background: $primary;
//   border: 0;
//   padding: 10px 35px;
//   color: $white;
//   transition: 0.4s;
//   border-radius: 50px;
// }
// .contact .php-email-form button[type=submit]:hover {
//   background: #0678e3;
// }
@-webkit-keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes animate-loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/
#footer {
  background: $dark;
  color: $navbar-dark-color;
  font-size: 14px;
  text-align: center;
  padding: 30px 0;
}
#footer h3 {
  font-size: 36px;
  font-weight: 700;
  position: relative;
  color: $navbar-dark-color;
  padding: 0;
  margin: 0 0 15px 0;
}
#footer p {
  font-size: 15;
  font-style: italic;
  padding: 0;
  margin: 0 0 40px 0;
}
#footer .social-links {
  margin: 0 0 40px 0;
}
#footer .social-links a {
  font-size: 18px;
  display: inline-block;
  background: $navbar-light-color;
  color: $navbar-dark-color;
  line-height: 1;
  padding: 9px 0;
  margin: 0px 3px;
  border-radius: 50%;
  text-align: center;
  width: 36px;
  height: 36px;
  transition: 0.3s;
}
#footer .social-links a:hover {
  background: $link-hover-color;
  color: $navbar-dark-active-color;
  text-decoration: none;
}
#footer .copyright {
  margin: 0 0 5px 0;
}
#footer .credits {
  font-size: 13px;
}